import * as React from 'react';
import Base from '@webLayouts/Base/Base';
import Designs from '@webPages/ModularHomes/Designs';
import QAllDesigns from '@staticQueries/AllDesigns';
import QStaticPageModularHomesDesigns from '@staticQueries/StaticPageModularHomesDesigns';

const IndexPage: React.FC = () => {
  return (
    <QStaticPageModularHomesDesigns>
      {({ seoTitle, seoDescription, title, copy }) => (
        <Base
          theme="light"
          title={`Two bedroom | ${seoTitle}`}
          description={seoDescription}
        >
          <QAllDesigns>
            {designs => (
              <Designs
                tab="designs"
                designs={designs}
                title={`2 Bedroom ${title}`}
                filter={[{ key: 'bedrooms', values: [2] }]}
                copy={copy}
              />
            )}
          </QAllDesigns>
        </Base>
      )}
    </QStaticPageModularHomesDesigns>
  );
};

export default IndexPage;
